import React from 'react'
import Navbar from '../../navbar/Navbar'
import Sidebar from '../../sidebar/Sidebar'
import './home.scss'
const Home = () => {
    return (
        <div>
            <Navbar />
            <Sidebar />

        </div>
    )
}

export default Home