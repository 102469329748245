import "./navbar.scss"
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from 'recoil'
import { urlState } from '../../utils/atoms'
import useUsers from "../../utils/hooks/useUsers";
import Logout from  "../../assets/logout.svg";


const Navbar = () => {

  const navigate = useNavigate();
  const [url] = useRecoilState(urlState);
  const [users] = useUsers();
  
  const signOut = () => {
    localStorage.removeItem("token");
    window.location.reload();
    navigate("/");
    
  };

  return (
    <div className="navbar">
      <div className="wrapper">
       
        <div className="navItems">
          {/* <img src="" alt="" /> */}
        <div className="adminLog">
            <p>{users && users.username }</p>             
              <p>Web administrator</p>
            </div>
            <img src={Logout} alt="" className="logout" onClick={signOut}/>
        </div>
       

      </div>
    </div>
  )
}

export default Navbar