import React, { useEffect, useRef, useState } from 'react'
import { updateVideos } from '../../../../utils/api'
import {ReactComponent as Load} from '../../../../assets/load.svg';
import "./editpop.scss"


const EditPop = (props) => {

    const [video_id, setVideoId] = useState('');
    const [video, setVideo] = useState('');
    const [isLoading, setIsLoading] = useState(false)

    const handleSent = async () => {

        console.log(video);
        const videoForm = new FormData()

        const data = {
            "video": video,
            'is_active': 'true',
        }

        videoForm.append("video", data.video[0])
        videoForm.append("is_active", true)

        try {
            setIsLoading(true)
            await updateVideos(video_id, videoForm)
            setIsLoading(false)
            props.handleClose();
            window.location.reload();

        } catch (error) {
            console.log(error)
            alert('Something went wrong');
        }
      
    }

    useEffect(() => {
        setVideoId(localStorage.getItem('id'));
    }, [])
    return (
        <div className="popup-box">
         
            <div className="box">
                <div className="modal-header">
                    <h2>Edit video</h2>
                </div>
                <div className="modal-body">
                    <form onSubmit={(e) => { e.preventDefault() }}>
                        <div className="popCard">
                            <label htmlFor="imgTag"> Choose video
                                <input id="imgTag" type="file" name='video'
                                    onChange={(e) => { setVideo(e.target.files) }} />
                            </label>
                        </div>
                        <button className="close-icon" onClick={props.handleClose}>x</button>
                        <button type='submit' className="save-btn" onClick={handleSent}>Save</button>

                    </form>
                </div>
            </div>
            {isLoading ?   <Load className='loading'/> : null}
        </div>
    );
}

export default EditPop