
import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import { postLanguage } from '../../../../utils/api';
import Snackbar from './SnackBar/snackbar';


const SnackbarType = {
    success: "success",
    fail: 'fail',
}
const LangAdd = (props) => {

    const navigate = useNavigate();
    const [langName, setName] = useState('');
    const [langCode, setCode] = useState('');

    const snackbarRef = useRef(null)

    const postData = async () => {
        try {
            await postLanguage(new URLSearchParams({
                name: langName,
                code: langCode
            }))
                .then(snackbarRef.current.show(),
                    setTimeout(() => {
                        window.location.reload()
                    }, 1000)
                )
        }

        catch (error) {
            console.log(error)
            // .then(snackbarRef.current.show(),
            //         setTimeout(() => {
            //             window.location.reload()
            //         }, 2000)
            //     );
            alert('wrong')
        }
       
        
    }
    return (
        <div className="popup-box">
            <div className="box">
                <div className="modal-header">
                    <h2>Add language</h2>
                </div>
                <div className="modal-body">
                    <div className="popCard">
                        <input type="text" placeholder="Language" onChange={(e) => setName(e.target.value)} />
                        <input type="text" placeholder="Code" onChange={(e) => setCode(e.target.value)} />
                    </div>
                    <button className="close-icon" onClick={props.handleClose}>x</button>
                    <button className="save-btn" onClick={postData} type='submit'>Add</button>
                    <Snackbar ref={snackbarRef} message="Language added!!" type={SnackbarType.success} />
                    {/* <Snackbar ref={snackbarRef} message="Something went wrong!!" type={SnackbarType.fail} /> */}
                </div>
            </div>

        </div>
    )
}

export default LangAdd