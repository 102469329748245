import Login from "./Login/Login";
import Home from "./components/pages/Home/Home";
import Video from "./components/pages/Video/Video";
import { useEffect, useState } from "react";
import "./style.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Languages from "./components/pages/Languages/Languages";
import Admin from "./components/pages/Admin/Admin";
import Projects from "./components/pages/Projects/Projects";
import Followers from "./components/pages/Followers/Followers";

function App() {
	const [isLoggedin, setIsLoggedIn] = useState(false);

	useEffect(() => {
		if (localStorage.getItem("token")) {
			setIsLoggedIn(true);
		} else {
			setIsLoggedIn(false);
		}
	}, []);

	return (
		<div className="App">
			<BrowserRouter>
				{isLoggedin ? (
					<div>
						<Routes>
							<Route index path="*" element={<Home />} />
						</Routes>
						<Routes>
							<Route path="video" element={<Video />} />
							<Route path="languages" element={<Languages />} />
							<Route path="admin" element={<Admin />} />
							<Route path="projects" element={<Projects />} />
							<Route path="followers" element={<Followers />} />
						</Routes>
					</div>
				) : (
					<Routes>
						<Route path="*" element={<Login />} />
					</Routes>
				)}
			</BrowserRouter>
		</div>
	);
}

export default App;
