import "./sidebar.scss";

import { NavLink } from "react-router-dom";

const Sidebar = () => {
  return (
    <div className="sidebar" id="main">
      <div className="logoSection">
        <NavLink to="/">
        
        </NavLink>
       
      </div>
      <div className="sideContent" id="mySidebar">
        <ul>
          <span>Authentification</span>
          <NavLink to="/admin">
            <li>Admin account</li>
          </NavLink>
        </ul>
        <hr />  
        <ul>
          {/* <span>Home</span> */}
         <NavLink to="/video">
         <li>Video</li>
         </NavLink>            
          <NavLink to="/projects">
          <li>Projects</li>
          </NavLink>
          <NavLink to="/followers">
         <li>Followers</li>
         </NavLink>
        </ul>
       {/* <hr />
         <ul>
         <NavLink to="/languages">
         <li>Languages</li>
         </NavLink>
        </ul> */}
      </div>
    </div>
  )
}

export default Sidebar