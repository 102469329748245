import axios from 'axios'

const axiosInstance = axios.create({baseURL: "https://api.techman-pro.com/"})
axiosInstance.defaults.headers['authorization'] = `Bearer ${localStorage.getItem('token')}`


const getUsers = () => {
    return axiosInstance.get("users/admin/me")  

}

const updateUsers = (user_id,formData) => {
    return axiosInstance.put(`users/admin/${user_id}`,formData)  

}

const getVideos = () => {
    return axiosInstance.get("main_video/admin/all")  

}

const updateVideos = (video_id,formData) => {
    return axiosInstance.put(`main_video/admin/${video_id}`,formData)  

}

const getFollowers = () => {
    return axiosInstance.get("follow_us/admin/follow/all")  

}

const deleteFollowers = (id) => {
    return axiosInstance.delete(`follow_us/admin/${id}`)  

}


const getLanguages = () => {
    return axiosInstance.get("languages/admin/all")  

}

const getLanguage = () => {
    return axiosInstance.get(`languages/admin/${localStorage.getItem('language_id')}`)  

}

const postLanguage = (data) => {
    return axiosInstance.post('languages/admin/create', data)
}

const updateLanguage = (data, language_id) => {
    return axiosInstance.put(`languages/admin/${language_id}`, data)
}

const deleteLanguage = (language_id) => {
    return axiosInstance.delete(`languages/admin/${language_id}`)  

}

// LOGIN

const getToken = (form) => {
    return axiosInstance.post('authentication/admin/sign_in', form)
}


export { 
getUsers,
updateUsers,
getFollowers,
deleteFollowers,
getLanguages,
getLanguage,
deleteLanguage,
updateLanguage,
postLanguage,
getVideos,
updateVideos,




    // Login

    getToken
}
