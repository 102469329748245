import { useEffect, useState } from 'react'
import { getLanguages } from '../api'

const useLanguages = () => {
  
    const [languages, setLanguages] = useState()

    const getData = async(setFunc) => {
        const response = await getLanguages()

        setFunc(response.data)
        // console.log(response.data)
    }

    useEffect(()=>{
        getData(setLanguages)
        // console.log(languages)
    }, [])

    return [languages]
}

export default useLanguages