import { useEffect, useState } from 'react'
import { getLanguage } from '../api'

const useLanguageEdit = () => {
    const [languageEdit, setLanguageEdit] = useState()

    const getData = async(setFunc) => {
        const response = await getLanguage()

        setFunc(response.data)
        // console.log(response.data)
    }

    useEffect(()=>{
        getData(setLanguageEdit)
        // console.log(languageEdit)
    }, [])

    return [languageEdit]
}

export default useLanguageEdit