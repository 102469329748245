import { useMemo, useState } from 'react';
import useLanguages from '../../../utils/hooks/useLanguages';
import LangAdd from './LangAdd/LangAdd';

import LangEdit from "./LangEdit/LangEdit"
import './languages.scss'
const Languages = () => {

const setLangId = (id) => {
    localStorage.setItem('language_id',id)
    }

const [isOpen, setIsOpen] = useState(false);
const togglePopup = () => {
    setIsOpen(!isOpen);
}   

const [isAddLang, setIsAddLang] = useState(false);
const AddLangPopup = () => {
    setIsAddLang(!isAddLang);
}  

const [languages] = useLanguages();
const renderLanguagesCard = useMemo(() => {
    return (
        languages && languages.length > 0 ?
        languages && languages.map((language, ind) => {
            return (
                <div className="langCard" key={language.id} onClick={() =>{
                    togglePopup(); 
                    setLangId(language.id)}}>
                    {language.name}
                </div>
            )
        })
        :
        "No languages! Create some"
    )
}, [languages])

    return (
        <div className='container'>
            <h1>Languages</h1>
            <button className='addButton' onClick={AddLangPopup}>Add Language</button>
            <div className="languageSection">                
                {renderLanguagesCard}
            </div>
            {isOpen && <LangEdit handleClose={togglePopup} />}
            {isAddLang && <LangAdd handleClose={AddLangPopup} />}
           
            {/* <Snackbar message="Something went wrong"/> */}
        </div>
    )
}

export default Languages