import axios from 'axios'

const axiosInstance = axios.create({baseURL: "https://api.techman-pro.com/"})
axiosInstance.defaults.headers['authorization'] = `Bearer ${localStorage.getItem('token')}`

const getProjects = () => {
    return axiosInstance.get("projects/admin/all")
}

const getOneProject = () => {  
    return axiosInstance.get(`projects/admin/${localStorage.getItem('id')}?lang=en`)
}

const getOneProjectTm = () => {  
    return axiosInstance.get(`projects/admin/${localStorage.getItem('id')}?lang=tm`)
}


const createProjects = (formData) => {
    return axiosInstance.post("projects/admin/create", formData)
    
}
const createTranslated = (formData) => {
    return axiosInstance.post("projects/admin/create", formData)
    
}

const updateProjects = (id,formData) => {
    return axiosInstance.put(`projects/admin/${id}`, formData)
}

const createProjectImages = (project_id,formData) => {
    return axiosInstance.post(`projects/admin/${project_id}/add_project_images`, formData)
}

const updateProjectImages = (id,project_id,formData) => {
    return axiosInstance.put(`projects/admin/${project_id}/update_project_image/${id}`, formData)
}

const deleteProjectImages = (project_id, project_image_id) => {
    return axiosInstance.delete(`projects/admin/${project_id}/delete_project_image/${project_image_id}`)
    
}

const createProjectVideos = (project_id,formData) => {
    return axiosInstance.post(`projects/admin/${project_id}/add_project_videos`, formData)
      
}


const deleteProjectVideos = (project_id, project_video_id) => {
    return axiosInstance.delete(`projects/admin/${project_id}/delete_project_video/${project_video_id}`)
   
}

const deleteProject = (proj_id) => {
    return axiosInstance.delete(`projects/admin/${proj_id}`)
    
}

export   {
    getProjects,
    getOneProject,
    getOneProjectTm,
    createProjects,
    createTranslated,
    createProjectImages,
    updateProjects,
    updateProjectImages,
    deleteProjectImages,
    createProjectVideos,
    deleteProjectVideos,
    deleteProject
}
