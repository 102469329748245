import { render } from '@testing-library/react';
import React, { useState, useEffect, useMemo } from 'react'
import { updateLanguage, deleteLanguage } from '../../../../utils/api';
import useLanguageEdit from '../../../../utils/hooks/useLanguageEdit'

import './langedit.scss'
const LangEdit = (props) => {

  const [language_id, setLanguageId] = useState('');
  const [editlangName, setName] = useState('');
  // const [editlangCode, setCode] = useState('');
  const [language] = useLanguageEdit();

  const putData = async () => {
    try {
      await updateLanguage(new URLSearchParams({
        name: editlangName,
      }), language_id)
   
      props.handleClose();
      window.location.reload();
    }

    catch (error) {
      alert('Something went wrong');
      console.log(error)
      props.handleClose();
    }


  }

  const handleDelete = async () => {
    try {
      await deleteLanguage(language_id)
    }
    catch (error) {
      console.log(error)
      alert('Something went wrong');
    }
    props.handleClose();
    window.location.reload();

  }

  useEffect(() => {
    setLanguageId(localStorage.getItem('language_id'));
  }, [])


  return (
    <div className="lang-pop">
      <div className="langbox">
        <div className="modal-header">
          <h2>Edit Language</h2>
        </div>
        {/* {renderLanguagesEdit} */}
        <div className="modal-body">
          <div className="popCard">
            <input name='editlangName' type="text" placeholder={language && language.name} onChange={(e) => setName(e.target.value)} />
            <input name='editlangCode' type="text" value={language && language.code} readOnly />
            {/* <input name='editlangCode' type="text" value={language && language.code} onChange={(e) => setCode(e.target.value)} /> */}
          </div>
          <button className="close-icon" onClick={props.handleClose}>x</button>

          <button className="delete-icon" onClick={handleDelete}>Delete</button>
          <button className="save-icon" type='submit' onClick={putData}>Save</button>

        </div>
      </div>

    </div>
  )
}

export default LangEdit