import { useRecoilState } from "recoil";
import { useMemo, useState } from 'react'
import { urlState } from "../../../utils/atoms";
import useProjects from "../../../utils/hooks/projectHooks/useProjects";
import EditProject from "./EditProject/EditProject";
import "./projects.scss"
import AddProject from "./AddProject/AddProject";

const Projects = () => {
  const [url] = useRecoilState(urlState);
  const [projects] = useProjects();
  const [isProject, setIsProject] = useState(false);
  const ProjectPopup = () => {
    setIsProject(!isProject);
  }

  const [isAddProd, setIsAddProd] = useState(false);
  const AddProdPopup = () => {
    setIsAddProd(!isAddProd);
  }

  const setId = (id) => {
    localStorage.setItem('id', id)
  }

  const renderProjectCard = useMemo(() => {
    return (
      projects && projects.length > 0 ?
        projects.map((project, ind) => {
          return (
            <div className="projectCard" key={project.id} >
              <div className="projHead">
                <h5><span>{project.title_highlight}</span> {project.title_head}</h5>
              </div>
              <div className="imgCard" style={{ backgroundImage: `url(${url + project.multimedia})` }}>
              </div>
              <button onClick={() => {
                ProjectPopup();
                setId(project.id)                
              }}>Edit</button>
            </div>
          )
        })
        :
        "No project uploaded!"
    )
  }, [projects])

  return (
    <div className='container'>
      <h1>Projects</h1>
      <button className='addButton' onClick={AddProdPopup}>Add Project</button>
      <div className="projectSection">
        {renderProjectCard}
        {isProject && <EditProject handleClose={ProjectPopup} />}
        {isAddProd && <AddProject handleClose={AddProdPopup} />}
      </div>
    </div>
  )
}

export default Projects